import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            textAlign: "center",
            marginBottom: theme.spacing(4),
        },
    })
);

interface PageTitleProps {
    title: string;
}

const PageTitle: React.FC<PageTitleProps> = (props) => {
    const classes = useStyles();
    return (
        <div>
            <h1 className={classes.title}>{props.title}</h1>
        </div>
    );
};

export default PageTitle;
