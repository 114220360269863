import React from "react";
import { Link, PageProps } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import PageTitle from "../components/PageTitle";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

/* cSpell:disable */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            maxWidth: 600,
        },
    })
);

const TypePage: React.FC<PageProps> = (props) => {
    const classes = useStyles();
    return (
        <Layout>
            <SEO title="About" />
            <PageTitle title="Typography" />
            <h1>Material UI</h1>
            <div className={classes.root}>
                <Typography variant="h1" component="h2" gutterBottom>
                    h1. Heading
                </Typography>
                <Typography variant="h2" gutterBottom>
                    h2. Heading
                </Typography>
                <Typography variant="h3" gutterBottom>
                    h3. Heading
                </Typography>
                <Typography variant="h4" gutterBottom>
                    h4. Heading
                </Typography>
                <Typography variant="h5" gutterBottom>
                    h5. Heading
                </Typography>
                <Typography variant="h6" gutterBottom>
                    h6. Heading
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    subtitle1. Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Quos blanditiis tenetur
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    subtitle2. Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Quos blanditiis tenetur
                </Typography>
                <Typography variant="body1" gutterBottom>
                    body1. Lorem ipsum dolor sit amet, consectetur adipisicing
                    elit. Quos blanditiis tenetur unde suscipit, quam beatae
                    rerum inventore consectetur, neque doloribus, cupiditate
                    numquam dignissimos laborum fugiat deleniti? Eum quasi
                    quidem quibusdam.
                </Typography>
                <Typography variant="body2" gutterBottom>
                    body2. Lorem ipsum dolor sit amet, consectetur adipisicing
                    elit. Quos blanditiis tenetur unde suscipit, quam beatae
                    rerum inventore consectetur, neque doloribus, cupiditate
                    numquam dignissimos laborum fugiat deleniti? Eum quasi
                    quidem quibusdam.
                </Typography>
                <Typography variant="button" display="block" gutterBottom>
                    button text
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                    caption text
                </Typography>
                <Typography variant="overline" display="block" gutterBottom>
                    overline text
                </Typography>
            </div>
            <h1>Plain Html</h1>
            <div className={classes.root}>
                <h1>h1. Heading</h1>
                <h2>h2. Heading</h2>
                <h3>h3. Heading</h3>
                <h4>h4. Heading</h4>
                <h5>h5. Heading</h5>
                <h6>h6. Heading</h6>
                <p>
                    In Belgium, all nonessential hospital work has been
                    postponed to deal with an influx of new Covid-19 patients,
                    whose numbers have nearly doubled in the past week, matching
                    levels seen in the first wave of the pandemic in the spring.
                </p>
                <p>
                    In Belgium, all nonessential hospital work has been
                    postponed to deal with an influx of new Covid-19 patients,
                    whose numbers have nearly doubled in the past week, matching
                    levels seen in the first wave of the pandemic in the spring.
                </p>
                <span>
                    span. Lorem ipsum dolor sit amet, consectetur adipisicing
                    elit. Quos blanditiis tenetur
                </span>
            </div>
        </Layout>
    );
};

export default TypePage;
